import React from 'react';

import {Fade} from 'react-awesome-reveal';

import iphoneFrameHome from '../assets/images/iPhoneFrame1.w375.png';
import iphoneFrameApply from '../assets/images/iPhoneFrame3Apply.png';
import iphoneFrameRating from '../assets/images/iPhoneFrame4Rating.png';
import talentDiscovery from '../assets/images/talent-discovery.png';
import videoPainting from '../assets/videos/painting.webm';
import Circle from '../components/circle/Circle';
import Icon from '../components/icon';
import ImageSection from '../components/imageSection/ImageSection';
import Phone from '../components/phone/Phone';
import PhoneVideo from '../components/phonevideo/PhoneVideo';
import {Language} from '../constants/language.constants';
import {PageType} from '../download/constants';
import {DownloadBadges} from '../components/download/DownloadBadges';

export function LandingPage(): JSX.Element {
  return (
    <>
      {/* Hero */}
      <ImageSection
        className="mt-20 md:mt-0"
        alignImage="right"
        featureImage={
          <Fade direction="right" delay={150} triggerOnce>
            <PhoneVideo src={videoPainting} frame="jenney" />
          </Fade>
        }
        backgroundImage={
          <>
            <Circle
              colors={['#FFC148', '#FF5975']}
              size={'70vw'}
              right={'-40vw'}
              top={'-40vw'}
            />
            <Circle
              colors={['#FF5975', '#5A68FF']}
              size={'50vw'}
              left={'-20vw'}
              top={'70vh'}
            />
          </>
        }>
        <Fade direction="down" triggerOnce>
          <h1 className="App-section-h2 m-0">
            Welcome to Slip, your personal career buddy
          </h1>
        </Fade>
        <Fade direction="up" triggerOnce>
          <p className="App-section-h4 m-0 font-light">
            Slip helps you build a professional profile that stands out. Whether
            you're just starting or already experienced, Slip guides you to
            achieve your career goals.
          </p>
          <DownloadBadges
            type={PageType.DOWNLOAD_MAIN}
            language={Language.EN}
            platforms={['iOS', 'Android']}
            size="small"
          />
        </Fade>
      </ImageSection>

      {/* Celebrate together */}
      <ImageSection
        className="h-screen"
        featureImage={
          <Fade direction="up" triggerOnce>
            <Phone src={iphoneFrameHome} />
          </Fade>
        }>
        <Fade direction="down" triggerOnce>
          <h2 className="App-section-h4 m-0">Capture Your Milestones</h2>
        </Fade>
        <Fade direction="up" triggerOnce>
          <p className="App-section-h3 m-0">
            Record important moments for your career growth using the camera to
            save photos or videos of achievements and projects. Share them with
            your network, select groups, or keep them private. Contribute to the
            community by sharing what inspires you and keep your profile active.
          </p>
        </Fade>
      </ImageSection>

      {/* Join Community */}
      <ImageSection
        className="h-screen my-6 sm:my-0"
        alignImage="right"
        featureImage={
          <Fade direction="up" triggerOnce>
            <div>
              <Phone src={iphoneFrameRating} />
            </div>
          </Fade>
        }
        backgroundImage={
          <>
            <Circle
              colors={['#FFCC51', '#FF8E65']}
              size={'75vw'}
              left={'50vw'}
            />
            <Circle
              colors={['#FFCC51', '#FFFF40']}
              size={'50vw'}
              left={'-20vw'}
              className="bottom-[-20vh] md:bottom-[-50vh]"
            />
          </>
        }>
        <h2 className="App-section-h4 m-0">Receive Targeted Feedback</h2>
        <p className="App-section-h3 m-0">
          Use Slip’s camera rating to receive feedback from classmates,
          colleagues, or professionals. Add these scores to your visual profile
          to showcase your skills and prepare for the job market with
          confidence.
        </p>
      </ImageSection>

      {/* Share and Connect */}
      <section className="p-12 my-6 sm:my-0 relative App-section-container-bg flex flex-col items-center justify-center h-[60vh] md:h-screen z-30">
        <div className="container">
          <Fade direction="down" triggerOnce>
            <h2 className="App-section-h4 text-white m-0">
              Discover Inspiring Content
            </h2>
          </Fade>
          <Fade direction="up" triggerOnce>
            <p className="App-section-h3 text-white m-0">
              Through your feed, explore contributions from others. On the
              Explore screen, dive deeper and discover professionals, companies,
              and their work. View photos and videos, learn from their
              experiences, and get inspired for your own development. Stay up to
              date with the latest trends and advancements in your field.
            </p>
          </Fade>
        </div>
      </section>

      {/* Challenge the world */}
      <ImageSection
        className="h-screen my-8 sm:my-0"
        featureImage={
          <Fade direction="up" triggerOnce>
            <div>
              <Phone src={iphoneFrameApply} />
            </div>
          </Fade>
        }
        backgroundImage={
          <Circle
            colors={['#FFFF40', '#FFCC51']}
            size={'40vw'}
            left={'-10vw'}
            top={'40vh'}
          />
        }>
        <div className="App-challenge max-w-xs md:max-w-xl">
          <h4 className="App-section-h4 text-white m-0">Network and Apply</h4>
          <p className="App-section-h3 text-white m-0">
            Find job opportunities and apply directly in Slip. Your visual
            profile is automatically included, making applications quick and
            professional.
          </p>
        </div>
      </ImageSection>

      {/* Forbusiness */}
      <ImageSection
        className="h-screen mt-16 sm:mt-0"
        alignImage="right"
        featureImage={
          <Fade direction="up" triggerOnce>
            <div className="flex flex-col items-center justify-center">
              <img src={talentDiscovery} alt="phone" />
            </div>
          </Fade>
        }>
        <h4 className="App-section-h4 m-0">Discover new Talent</h4>
        <h2 className="App-section-h3 m-0">
          Browsing through our platform, you'll discover a sea of potential
          candidates showcasing their skills and achievements in creative ways.
          Find out more business features.
        </h2>
        <div className="App-section-button flex flex-row items-center text-3xl md:text-4xl text-primary font-bold">
          <a href="https://forbusiness.slip-app.nl">Business</a>
          <Icon name="arrow-forward-outline" size={'large'} />
        </div>
      </ImageSection>

      {/* Download Section */}
      <section className="relative flex flex-col">
        <div className="flex flex-col items-center justify-center mb-44 container mx-auto">
          <h4 className="App-section-h3 font-semibold">
            With Slip, you’re ready to grow your career, from school days to
            dream jobs. Start today and reach your goals step by step.
          </h4>
          <DownloadBadges
            type={PageType.DOWNLOAD_MAIN}
            language={Language.EN}
            platforms={['iOS', 'Android']}
          />
        </div>
      </section>
    </>
  );
}
